var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb35 my-profile-custom"},[_c('div',{staticClass:"row mb15 middle-xs"},[_c('div',{staticClass:"col-xs-6 col-sm-6"},[_c('h3',{staticClass:"m0 mb5 fs28 weight-500"},[_vm._v("\n        "+_vm._s(_vm.$t('My profile'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-6 col-sm-6"},[(!_vm.isEdited)?_c('div',{staticClass:"lh30 flex end-xs"},[_c('a',{staticClass:"middle-xs flex cl-black",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit($event)}}},[_c('span',{staticClass:"pr5 lh16"},[_vm._v("\n            "+_vm._s(_vm.$t('Edit your profile'))+"\n          ")]),_vm._v(" "),_c('i',{staticClass:"icon-create_black"})])]):_vm._e()])]),_vm._v(" "),(_vm.isEdited)?_c('div',{staticClass:"row bg-cl-white brdr-1 brdr-cl-black p30"},[_c('base-input',{staticClass:"col-xs-12 col-md-6 mb20",attrs:{"type":"text","name":"first-name","autocomplete":"given-name","placeholder":_vm.$t('First name'),"validations":[
        {
          condition: !_vm.$v.currentUser.firstname.required,
          text: _vm.$t('Field is required')
        },
        {
          condition: !_vm.$v.currentUser.firstname.minLength,
          text: _vm.$t('Name must have at least 2 letters.')
        }
      ]},on:{"input":function($event){return _vm.$v.currentUser.firstname.$touch()}},model:{value:(_vm.currentUser.firstname),callback:function ($$v) {_vm.$set(_vm.currentUser, "firstname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.firstname"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-12 col-md-6 mb20",attrs:{"type":"text","name":"last-name","autocomplete":"family-name","placeholder":_vm.$t('Last name'),"validations":[{
        condition: !_vm.$v.currentUser.lastname.required,
        text: _vm.$t('Field is required')
      }]},on:{"input":function($event){return _vm.$v.currentUser.lastname.$touch()}},model:{value:(_vm.currentUser.lastname),callback:function ($$v) {_vm.$set(_vm.currentUser, "lastname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.lastname"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-12 col-md-6 mb20",attrs:{"type":"email","name":"email-address","autocomplete":"email","placeholder":_vm.$t('Email address'),"validations":[
        {
          condition: !_vm.$v.currentUser.email.required,
          text: _vm.$t('Field is required')
        },
        {
          condition: !_vm.$v.currentUser.email.email,
          text: _vm.$t('Please provide valid e-mail address.')
        }
      ]},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-12 col-md-6 mb20",attrs:{"type":"text","name":"bank-account-number","placeholder":_vm.$t('Account number for returns'),"validations":[
        {
          condition: !_vm.$v.bankAccountNumber.required && _vm.$v.bankAccountNumber.$error,
          text: _vm.$t('Field is required')
        },
        {
          condition: !_vm.$v.bankAccountNumber.numeric && _vm.$v.bankAccountNumber.$error,
        },
        {
          condition: !_vm.$v.bankAccountNumber.minLength && _vm.$v.bankAccountNumber.$error,
          text: _vm.$t('The bank account number must contain 26 digits')
        },
        {
          condition: !_vm.$v.bankAccountNumber.maxLength && _vm.$v.bankAccountNumber.$error,
          text: _vm.$t('The bank account number must contain 26 digits')
        }
      ]},on:{"input":function($event){return _vm.$v.bankAccountNumber.$touch()}},model:{value:(_vm.bankAccountNumber),callback:function ($$v) {_vm.bankAccountNumber=$$v},expression:"bankAccountNumber"}}),_vm._v(" "),_c('base-checkbox',{staticClass:"col-xs-12 mb20",attrs:{"id":"changePassword"},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}},[_vm._v("\n      "+_vm._s(_vm.$t('Change my password'))+"\n    ")]),_vm._v(" "),(_vm.changePassword)?[_c('base-input',{staticClass:"col-xs-12 col-md-6 mb15 mt10",attrs:{"type":"password","name":"old-password","autocomplete":"current-password","placeholder":_vm.$t('Current password *'),"validations":[{
          condition: !_vm.$v.oldPassword.required && _vm.$v.oldPassword.$error,
          text: _vm.$t('Field is required')
        }]},on:{"input":function($event){return _vm.$v.oldPassword.$touch()}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_vm._v(" "),_c('div',{staticClass:"hidden-xs hidden-sm col-md-6 mb15 mt10"}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-12 col-md-6 mb15 mt10",attrs:{"type":"password","name":"password","autocomplete":"new-password","placeholder":_vm.$t('New password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 6 letters.')
          }
        ]},on:{"input":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-12 col-md-6 mb15 mt10",attrs:{"type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":_vm.$t('Repeat new password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword,
            text: _vm.$t('Passwords must be identical.')
          }
        ]},on:{"input":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('button-full',{staticClass:"w-100",attrs:{"disabled":_vm.checkValidation()},nativeOn:{"click":function($event){return _vm.updateProfile($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Update my profile'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-6 flex middle-xs"},[_c('p',{staticClass:"h4 m0 cl-black pointer",on:{"click":_vm.exitSection}},[_vm._v("\n        "+_vm._s(_vm.$t('Cancel'))+"\n      ")])])],2):_c('div',{staticClass:"row fs16 mb35"},[_c('div',{staticClass:"col-xs-12"},[_c('p',{staticClass:"mt0 cl-light-gray"},[_vm._v("\n        "+_vm._s(_vm.currentUser.firstname)+" "+_vm._s(_vm.currentUser.lastname)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt0 cl-light-gray"},[_vm._v("\n        "+_vm._s(_vm.currentUser.email)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt0 cl-light-gray"},[_vm._v("\n        "+_vm._s(_vm.$t('Account number for returns'))+": "+_vm._s(_vm.readBankAccountNumber())+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
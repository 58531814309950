<template>
  <div class="mb35" v-if="order">
    <!-- My order header -->
    <div class="row mb15">
      <div class="col-xs-12 col-md-6 flex middle-xs">
        <i class="icon-chevron-left mr10 cl-main-black pointer" @click="goBack" />
        <h3 class="m0 h2 weight-500 mb5 mt5">
          {{ $t('Order #{id}', { id: order.increment_id }) }}
        </h3>
        <span class="brdr-1 brdr-cl-bg-secondary py5 px10 ml20 sans-serif fs-medium-small weight-400 cl-secondary">
          {{ $t(order.status) | capitalize }}
        </span>
      </div>
    </div>
    <!-- My order body -->
    <div class="row fs16">
      <div class="col-xs-12">
        <p>{{ order.created_at | date('LLL', storeView) }}</p>
        <!-- <p class="m0">
          <a href="#" class="underline cl-black weight-600" @click.prevent="remakeOrder(singleOrderItems)">{{ $t('Remake order') }}</a>
        </p> -->
      </div>
    </div>
    <div class="row mb35">
      <div class="col-xs-12">
        <div class="mb20">
          {{ $t('Items ordered') }}
        </div>
        <table class="brdr-1 brdr-cl-bg-secondary">
          <thead>
            <tr>
              <th class="py10 weight-500 uppercase">
                {{ $t('Product Name') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('SKU') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('Price') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('Qty') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('Subtotal') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('Thumbnail') }}
              </th>
              <th class="py10 weight-500 uppercase">
                {{ $t('Data wypożyczenia') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="brdr-top-1 brdr-cl-bg-secondary" v-for="item in singleOrderItems" :key="item.item_id">
              <td class="" :data-th="$t('Product Name')">
                {{ item.name }}
              </td>
              <td class="" :data-th="$t('SKU')">
                {{ item.sku }}
              </td>
              <td class="" :data-th="$t('Price')">
                {{ item.price_incl_tax | price(storeView) }}
              </td>
              <td class="" :data-th="$t('Qty')">
                {{ item.qty_ordered }}
              </td>
              <td class="" :data-th="$t('Subtotal')">
                {{ item.row_total_incl_tax | price(storeView) }}
              </td>
              <td class="" :data-th="$t('Thumbnail')">
                <product-image v-show="itemThumbnail[item.sku]" class="ordered-product-image" :image="itemThumbnail[item.sku]" />
              </td>
              <td :data-th="$t('Data wypożyczenia')">
                {{ getOrderDate(item) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="brdr-top-1 brdr-cl-bg-secondary mb10">
              <td colspan="5" class="align-right">
                {{ $t('Subtotal') }}
              </td>
              <td>{{ order.subtotal | price(storeView) }}</td>
            </tr>
            <tr>
              <td colspan="5" class="align-right">
                {{ $t('Shipping') }}
              </td>
              <td>{{ order.shipping_amount | price(storeView) }}</td>
            </tr>
            <tr>
              <td colspan="5" class="align-right">
                {{ $t('Tax') }}
              </td>
              <td>{{ order.tax_amount + order.discount_tax_compensation_amount | price(storeView) }}</td>
            </tr>
            <tr v-if="order.discount_amount">
              <td colspan="5" class="align-right">
                {{ $t('Discount') }}
              </td>
              <td>{{ order.discount_amount | price(storeView) }}</td>
            </tr>
            <tr>
              <td colspan="5" class="align-right weight-600">
                {{ $t('Grand total') }}
              </td>
              <td>{{ order.grand_total | price(storeView) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row mb35">
      <div class="col-xs-12 order-info-col">
        <div class="mb20">{{ $t('Order informations') }}</div>
        <div class="row">
          <div class="col-sm-6 col-md-3" v-if="shippingAddress">
            <p class="title">
              {{ $t('Shipping address') }}
            </p>
            <address>
              <p>{{ shippingAddress.firstname }} {{ shippingAddress.lastname }}</p>
              <p>{{ shippingAddress.street[0] }} {{ shippingAddress.street[1] }}</p>
              <p>{{ shippingAddress.postcode }} {{ shippingAddress.city }}</p>
              <p>{{ shippingAddress.country }}</p>
            </address>
          </div>
          <div class="col-sm-6 col-md-3" v-if="order.shipping_description">
            <p class="title">
              {{ $t('Shipping method') }}
            </p>
            <p>{{ order.shipping_description }}</p>
          </div>
          <div class="col-sm-6 col-md-3">
            <p class="title">
              {{ $t('Billing address') }}
            </p>
            <address>
              <p>{{ billingAddress.firstname }} {{ billingAddress.lastname }}</p>
              <p>{{ billingAddress.street[0] }} {{ billingAddress.street[1] }}</p>
              <p>{{ billingAddress.postcode }} {{ billingAddress.city }}</p>
              <p>{{ billingAddress.country }}</p>
            </address>
          </div>
          <div class="col-sm-6 col-md-3">
            <p class="title">
              {{ $t('Payment method') }}
            </p>
            <p>{{ paymentsMap(paymentMethod) }}</p>
          </div>
          <div v-show="orderTrack" class="col-sm-6 col-md-3 mt20">
            <p v-for="(order, index) in orderTrack" :key="index" class="title">
              <a
                class="underline"
                :href="`https://www.dhl.com/pl-en/home/tracking/tracking-parcel.html?submit=1&tracking-id=${order.track_number}`"
                target="_blank"
                rel="noreferrer nofollow"
              >
                {{ $t('Track your order') }} {{order.track_number}}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import MyOrder from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyOrder'
import ReturnIcon from 'theme/components/core/blocks/Header/ReturnIcon'
import ProductImage from 'theme/components/core/ProductImage'
import { getThumbnailPath, productThumbnailPath } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapActions } from 'vuex'

export default {
  mixins: [MyOrder],
  components: {
    ReturnIcon,
    ProductImage
  },
  data () {
    return {
      itemThumbnail: {}
    }
  },
  computed: {
    storeView () {
      return currentStoreView()
    },
    orderTrack () {
      let trackItems = this.order.extension_attributes?.shipment_tracks?.items
      return trackItems
    }
  },
  methods: {
    ...mapActions({
      getProduct: 'product/single'
    }),
    paymentsMap (methodCode) {
      let payemntMethods = {
        'vue_payu_gateway': 'PayU',
        'stripe_payments': 'Stripe'
      }
      return payemntMethods[methodCode] ? payemntMethods[methodCode] : methodCode
    },
    getOrderDate (item) {
      if (item?.product_option?.extension_attributes?.custom_options) {
        let customOtipons = item.product_option.extension_attributes.custom_options
        let startDate = customOtipons[0] // .find(option => option.option_id === '9459')
        let endDate = customOtipons[1] // .find(option => option.option_id === '9460')
        if (startDate && endDate) {
          return this.prettyDate(startDate.option_value[0]) + ' - ' + this.prettyDate(endDate.option_value[0])
        }
        return ''
      }
      return ''
    },
    prettyDate (date) {
      let toDate = new Date(date)
      let month = toDate.getMonth() + 1
      let prettyMonth = month < 10 ? '0' + month : month
      let prettyDay = toDate.getDate() < 10 ? '0' + toDate.getDate() : toDate.getDate()
      return prettyDay + '.' + prettyMonth + '.' + toDate.getFullYear()
    },
    goBack () {
      this.$router.back()
    }
  },
  mounted () {
    this.singleOrderItems.forEach(async item => {
      if (!this.itemThumbnail[item.sku]) {
        const product = await this.getProduct({ options: { sku: item.sku } })
        const thumbnail = productThumbnailPath(product)
        Vue.set(this.itemThumbnail, item.sku, thumbnail)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
$color-white-smoke: color(white-smoke);

table {
  border-collapse: collapse;
  width: 100%;

  @media (max-width: 767px) {
    border-top: none;
  }

  th, td {
    text-align: left;
    font-size: 0.9em;
    &.align-right {
      text-align: right;

      @media (max-width: 767px) {
        text-align: left;
      }

    }

    @media (max-width: 1199px) {
      padding: 10px;
    }

  }

  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {

    tr {
      @media (max-width: 767px) {
        display: block
      }

      &:nth-child(even) {
        td {
          background-color: $color-white-smoke;
        }
      }

    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      @media (max-width: 767px) {
        display: block;
        text-align: left;
        padding: 10px 20px;
        &:before {
          content: attr(data-th) ': ';
          font-weight: 700;
        }
      }

      &:first-child {
        @media (max-width: 767px) {
          padding: 20px 20px 10px 20px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          padding: 10px 20px 20px 20px;
        }
      }
    }

  }

  tfoot {

    tr {
      @media (max-width: 767px) {
        display: block
      }

      &:last-child {
        td:last-child {
         // padding-bottom: 20px
        }
      }

    }

    td {
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      @media (max-width: 767px) {
        display: block
      }

      &:first-child {
        @media (max-width: 767px) {
          font-weight: 700;
          padding: 20px 20px 5px 20px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          padding: 5px 20px 0 20px;
        }
      }

    }

  }

  i {
    vertical-align: middle;
  }

}

address {
  font-style: normal;
}

.ordered-product-image {
  @media (min-width: 768px) {
    /deep/ .product-image__thumb {
      max-width: 70px;
      height: auto;
    }
  }
}
.order-info-col {
  p.title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
    font-size: 0.9em;
  }
}
</style>

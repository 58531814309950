<template>
  <div class="mb35">
    <div class="row mb15 middle-xs">
      <div class="col-xs-6 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('My subscriptions') }}
        </h3>
      </div>
    </div>
    <div class="row" v-show="!getHistory.length">
      <div class="col-xs-12">
        {{ $t('Nie masz jeszcze dodanego abonamentu') }}
      </div>
      <div class="col-xs-12 mt20">
        <button-full
          class="pointer auto-width"
          :link="{ path: '/' + $t('abonament-modowy-wybierz-pakiet')}"
        >
          {{ $t('Aktywuj Plan Abonamentu') }}
        </button-full>
      </div>
    </div>
    <div class="row active-subscription-row" v-show="getHistory.length">
      <div class="col-xs-12 ">
        {{ $t('Current Balance') }}
      </div>
      <div class="col-xs-12 fs-big mb30">
        {{ getUserBalance }}
      </div>
      <div class="col-xs-12 mb20">
        {{ $t('Points History') }}
      </div>
      <div class="col-xs-12 active-subscription-col">
        <div class="row active-subscription hidden-xs">
          <span class="col-xs-3 weight-500 uppercase">{{ $t('comment') }}</span>
          <span class="col-xs-3 weight-500 uppercase">{{ $t('points amount') }}</span>
          <span class="col-xs-3 weight-500 uppercase">{{ $t('points left') }}</span>
          <span class="col-xs-3 weight-500 uppercase">{{ $t('action date') }}</span>
          <!-- <span class="col-xs-3 weight-500 uppercase">{{ $t('expiration id') }}</span> -->
        </div>
      </div>
      <div class="col-xs-12 active-subscription-col" v-for="(history, index) in getHistory" :key="index" v-if="history.visible_for_customer">
        <div class="row active-subscription">
          <span class="col-xs-12 col-sm-3">
            <span class="weight-500 uppercase visible-xs">{{ $t('comment') }}:</span>
            {{ history.comment }}
          </span>
          <span class="col-xs-12 col-sm-3 weight-600">
            <span class="weight-500 uppercase visible-xs">{{ $t('points amount') }}:</span>
            {{ history.amount }}
          </span>
          <span class="col-xs-12 col-sm-3 weight-600">
            <span class="weight-500 uppercase visible-xs">{{ $t('points left') }}:</span>
            {{ history.points_left }}
          </span>
          <span class="col-xs-12 col-sm-3">
            <span class="weight-500 uppercase visible-xs">{{ $t('action date') }}:</span>
            {{ history.action_date }}
          </span>
          <!-- <span class="col-xs-12 col-sm-3">
            <span class="weight-500 uppercase visible-xs">{{ $t('expiration id') }}:</span>
            {{ history.expiration_id }}
          </span> -->
        </div>
      </div>
      <!-- <div class="col-xs-12 mt40 mb20" v-show="getExpiration.length">
        {{ $t('Expiring Points Breakdown') }}
      </div>
      <div class="col-xs-12 active-subscription-col" v-show="getExpiration.length">
        <div class="row active-subscription hidden-xs">
          <span class="col-xs-4 weight-500 uppercase">{{ $t('transaction id') }}</span>
          <span class="col-xs-4 weight-500 uppercase">{{ $t('points amount') }}</span>
          <span class="col-xs-4 weight-500 uppercase">{{ $t('expiration date') }}</span>
        </div>
      </div>
      <div class="col-xs-12 active-subscription-col" v-for="(expiration, index) in getExpiration" :key="index + 'history'" v-show="getExpiration.length">
        <div class="row active-subscription">
          <span class="col-xs-12 col-sm-4">
            <span class="weight-500 uppercase visible-xs">{{ $t('transaction id') }}:</span>
            {{ expiration.entity_id }}
          </span>
          <span class="col-xs-12 col-sm-4 weight-600">
            <span class="weight-500 uppercase visible-xs">{{ $t('points amount') }}:</span>
            {{ expiration.amount }}
          </span>
          <span class="col-xs-12 col-sm-4">
            <span class="weight-500 uppercase visible-xs">{{ $t('expiration date') }}:</span>
            {{ expiration.date }}
          </span>
        </div>
      </div> -->
    </div>
    <button-full
      class="mt20 pointer auto-width"
      @click.native="refreshData"
    >
      {{ $t('Odśwież dane') }}
    </button-full>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'

export default {
  name: 'MySubscriptions',
  components: {
    ButtonFull
  },
  mixins: [onBottomScroll],
  data () {
    return {
      pagination: {
        perPage: 20,
        current: 1,
        enabled: false
      },
      lazyLoadOrdersOnScroll: true
    }
  },
  computed: {
    ...mapGetters({
      getSubscriptions: 'user/getSubscriptions',
      getExpiration: 'user/getExpiration',
      getHistory: 'user/getHistory',
      getUserBalance: 'user/getBalance'
    })
  },
  methods: {
    refreshData () {
      this.$store.dispatch('user/sessionAfterAuthorized', { refresh: true, useCache: false })
    },
    onBottomScroll () {
      const totalCount = this.getHistory.length ? this.getHistory.length : 0;
      const isLastPage = this.pagination.current > Math.ceil(totalCount / this.pagination.perPage);
      if (!isLastPage) {
        this.pagination.current++;
        this.$store.dispatch('user/appendUserHistory', {
          currentPage: this.pagination.current
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  // .active-subscription-box:last-child {
    .active-subscription-col:last-child {
      .active-subscription {
        border-bottom: 1px solid #D6D8DE;
      }
    }
  // }
  .active-subscription {
    padding: 10px 0;
    border-top: 1px solid #D6D8DE;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0.9em;
    div {
      @media (max-width: 767px) {
        margin: 5px 0;
      }
    }
  }
 .auto-width {
   width: fit-content;
 }
</style>

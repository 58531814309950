<template>
  <div id="my_account">
    <div class="pt40 pb20 px20 header">
      <div class="container">
        <breadcrumbs :with-homepage="true" :routes="[]" active-route="My Account" />
      </div>
    </div>
    <div class="container pt20 pb70 ">
      <div class="row px20 pt0 screen-padding">
        <div class="static-menu-box col-md-3 block brdr-right-2 brdr-cl-primary">
          <nav class="static-menu">
            <ul class="m0 pl0 pr20">
              <li class="static-menu-item align-right" v-for="(page, index) in navigation" :key="index" @click="notify(page.title)">
                <router-link :to="localizedRoute(page.link)" class="cl-black uppercase">
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-9">
          <no-ssr>
            <component class="pl20" :is="this.$props.activeBlock" />
          </no-ssr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAccount from '@vue-storefront/core/pages/MyAccount'
import Breadcrumbs from '../components/core/Breadcrumbs'
import MyProfile from '../components/core/blocks/MyAccount/MyProfile'
import MyShippingDetails from '../components/core/blocks/MyAccount/MyShippingDetails'
import MySubscriptions from '../components/core/blocks/MyAccount/MySubscriptions'
import MyActiveSubscriptions from '../components/core/blocks/MyAccount/MyActiveSubscriptions'
import MySubscriptionCards from '../components/core/blocks/MyAccount/MySubscriptionCards'
import MyOrders from '../components/core/blocks/MyAccount/MyOrders'
import MyOrder from '../components/core/blocks/MyAccount/MyOrder'
import MyRecentlyViewed from '../components/core/blocks/MyAccount/MyRecentlyViewed'
import MySellingProducts from '../components/core/blocks/MyAccount/MySellingProducts'
import NoSSR from 'vue-no-ssr'
import { RecentlyViewedModule } from '@vue-storefront/core/modules/recently-viewed'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      navigation: [
        { title: this.$t('My profile'), link: '/my-account' },
        { title: this.$t('My orders'), link: '/my-account/orders' },
        { title: this.$t('Sell'), link: '/my-account/my-selling-products' },
        { title: this.$t('My addresses'), link: '/my-account/shipping-details' },
        { title: this.$t('My subscriptions'), link: '/my-account/subscriptions' },
        { title: this.$t('My active subscriptions'), link: '/my-account/active-subscriptions' },
        { title: this.$t('My subscription cards'), link: '/my-account/cards' },
        { title: this.$t('My Recently viewed products'), link: '/my-account/recently-viewed' }
      ]
    }
  },
  components: {
    Breadcrumbs,
    MyProfile,
    MyShippingDetails,
    MySubscriptions,
    MyActiveSubscriptions,
    MyOrders,
    MyOrder,
    MyRecentlyViewed,
    MySubscriptionCards,
    MySellingProducts,
    'no-ssr': NoSSR
  },
  beforeCreate () {
    registerModule(RecentlyViewedModule)
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('selling/resetProducts')
    next()
  },
  mixins: [MyAccount],
  methods: {
    notify (title) {
      if (title === 'My loyalty card' || title === 'My product reviews') {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.$t('This feature is not implemented yet! Please take a look at https://github.com/DivanteLtd/vue-storefront/issues for our Roadmap!'),
          action1: { label: this.$t('OK') }
        })
      }
    }
  },
  metaInfo () {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '~theme/css/base/text';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-tertiary: color(tertiary);
$color-main: color(main);

#my_account {
  min-height: 40vh;
.static-menu-box {
  @media (max-width: 768px) {
    border: 0px;
    width: 100%;
  }
}
.col-md-9>.pl20 {
  @media (max-width: 768px) {
    padding-left: 0;
  }
}
.static-menu {
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
    li {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
        padding: 5px;
        text-align: center;
        border-radius: 15px;
        box-shadow: 0px 3px 6px #00000029;
        background-color: white;
      }
    }
  }
  .router-link-exact-active {
    color: $color-main;
    font-weight: bold;
    text-decoration: underline;
  }
  a {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // height: 1px;
      background-color: $color-tertiary;
    }

    &:hover,
    &.router-link-exact-active {
      &:after {
        opacity: 0;
      }
    }
  }
}
.screen-padding {
  @media (min-width: 768px) and (max-width: 1020px) {
    min-height: calc(100vh - 320px);
  }
}
.header {
  @media (max-width: 768px) {
    padding-top: 20px;
  }
}
}
</style>

<template>
  <div class="breadcrumbs cl-black relative" vocab="http://schema.org/" typeof="BreadcrumbList" ref="breadcrumbsWrapper">
    <template v-if="!sidebarMenu">
      <!-- <back-button v-if="paths.length > 0 " class="back-button" /> -->
      <span class="cl-black center-md flex" v-for="(link, index) in paths" :key="link.route_link" property="itemListElement" typeof="ListItem">
        <router-link :to="link.route_link" class="cl-black opacity-breadcrubms h5 links m0 pr5" property="item" typeof="WebPage" :href="hostName + link.route_link">
          <span class="size-mobile" property="name">
            <span class="hidden" v-if="breadcrumbsEmoji && (paths.length-1 === index)" v-html="breadcrumbsEmoji" />
            {{ link.name | htmlDecode }}
            </span>
        </router-link> <span class="pr5 h6 cl-black opacity-breadcrubms">/</span>
        <meta property="position" :content="index">
      </span>
    </template>
    <span class="opacity-breadcrubms title m0 h5 inline">
      {{ current === 'Sklep' ? 'Sklep internetowy - oferta detaliczna' : current | htmlDecode }}
    </span>

    <div class="breadcrumbsDots">...</div>
  </div>
</template>

<script>
import { Breadcrumbs } from '@vue-storefront/core/modules/breadcrumbs/components/Breadcrumbs.ts'
// import BackButton from 'theme/components/core/BackButton';
import config from 'config'

export default {
  props: {
    sidebarMenu: {
      required: false,
      type: Boolean
    }
  },
  components: {
    // BackButton
  },
  computed: {
    breadcrumbsEmoji () {
      return config.entities.category.breadcrumbsEmoji
    },
    hostName () {
      return config.server.hostName
    }
  },
  mixins: [Breadcrumbs],
  mounted () {
    this.showBreadcrumbsDots()

    window.addEventListener('resize', () => {
      this.showBreadcrumbsDots()
    });
  },
  destroyed () {
    window.removeEventListener('resize', this.showBreadcrumbsDots);
  },
  methods: {
    showBreadcrumbsDots () {
      if ((this.$refs.breadcrumbsWrapper?.offsetWidth + 10) > window?.innerWidth) {
        this.$refs.breadcrumbsWrapper.querySelector('.breadcrumbsDots').style.display = 'block';
      } else if (this.$refs.breadcrumbsWrapper) {
        this.$refs.breadcrumbsWrapper.querySelector('.breadcrumbsDots').style.display = 'none';
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.padding-mobile {
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}
.breadcrumbs {
  align-items: center;
  justify-content: start;
  display: flex;
  width: fit-content;
  max-width: calc(100% - 10px);
  position: relative;
  flex-wrap: wrap;
}
.links:hover {
  text-decoration: underline;
}
.opacity-breadcrubms {
  color: black;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.size-mobile {
  font-size: 13px;
  @media (max-width: 767px) {
    font-size: 11px;
  }
}
.hidden {
  display: none;
}
.inline {
  display: inline;
}
.breadcrumbsDots {
  color: black;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 2px;
  font-size: 13px;
  background-color: white;
  display: none;
}
</style>

<template>
  <div id="product-listing" class="product-listing mt30 row start-xs start-md">
    <div
      v-for="(product, index) in products"
      :key="product.id"
      class="flex product-listing__wrapper center-xs"
      :class="columnsClass()"
    >
      <product-tile
        :product="product"
        :list="currentCategoryName"
        :subscription="parsedSubscription"
        :columns="columns"
        :position="index"
        :recently-view="recentlyView"
      />
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import { SearchQuery } from 'storefront-query-builder'
import config from 'config'

export default {
  name: 'ProductListing',
  data () {
    return {
      subscription: {
        priceString: '',
        itemsToRent: ''
      }
    }
  },
  components: {
    ProductTile
  },
  props: {
    currentCategoryName: {
      type: String,
      required: false,
      default: 'catalog'
    },
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    filterToogle: {
      type: Boolean,
      default: false
    },
    recentlyView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.loadSubscriptionData()
  },
  computed: {
    parsedSubscription () {
      return { price: this.parseDesc(this.subscription.priceString), itemsToRent: this.subscription.itemsToRent }
    }
  },
  methods: {
    async loadSubscriptionData () {
      let searchQuery = new SearchQuery()
      searchQuery = searchQuery
        .applyFilter({ key: 'category_ids', value: { 'in': [config.server.categoryIdsMap.fashionSubscriptionCategoryId] } });
      ({ items: { 0: { description: this.subscription.priceString, points: this.subscription.itemsToRent } } } = await this.$store.dispatch('product/findProducts', {
        query: searchQuery,
        includeFields: ['description', 'points'],
        size: 1,
        sort: 'final_price:asc'
      }))
    },
    parseDesc (desc) {
      if (desc) {
        return desc.replace(/<\/?p>/g, '')
      } else {
        return ''
      }
    },
    columnsClass () {
      return this.columns === 'wishlist'
        ? 'col-md-4 col-xs-6' : this.columns === 'on'
          ? 'col-xs-6 col-sm-4 col-md-3'
          : 'col-xs-12'
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-listing {
    @media (max-width: 767px) {
      margin: 0;
      &__wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>

<template>
  <div class="mb35 my-profile-custom">
    <!-- My profile header -->
    <div class="row mb15 middle-xs">
      <div class="col-xs-6 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('My profile') }}
        </h3>
      </div>
      <div class="col-xs-6 col-sm-6">
        <div class="lh30 flex end-xs" v-if="!isEdited">
          <a href="#" class="middle-xs flex cl-black" @click.prevent="edit">
            <span class="pr5 lh16">
              {{ $t('Edit your profile') }}
            </span>
            <i class="icon-create_black" />
          </a>
        </div>
      </div>
    </div>

    <!-- My profile body (edit mode) -->
    <div class="row bg-cl-white brdr-1 brdr-cl-black p30" v-if="isEdited">
      <base-input
        class="col-xs-12 col-md-6 mb20"
        type="text"
        name="first-name"
        autocomplete="given-name"
        :placeholder="$t('First name')"
        v-model.trim="currentUser.firstname"
        @input="$v.currentUser.firstname.$touch()"
        :validations="[
          {
            condition: !$v.currentUser.firstname.required,
            text: $t('Field is required')
          },
          {
            condition: !$v.currentUser.firstname.minLength,
            text: $t('Name must have at least 2 letters.')
          }
        ]"
      />

      <base-input
        class="col-xs-12 col-md-6 mb20"
        type="text"
        name="last-name"
        autocomplete="family-name"
        :placeholder="$t('Last name')"
        v-model.trim="currentUser.lastname"
        @input="$v.currentUser.lastname.$touch()"
        :validations="[{
          condition: !$v.currentUser.lastname.required,
          text: $t('Field is required')
        }]"
      />

      <base-input
        class="col-xs-12 col-md-6 mb20"
        type="email"
        name="email-address"
        autocomplete="email"
        :placeholder="$t('Email address')"
        v-model="currentUser.email"
        :validations="[
          {
            condition: !$v.currentUser.email.required,
            text: $t('Field is required')
          },
          {
            condition: !$v.currentUser.email.email,
            text: $t('Please provide valid e-mail address.')
          }
        ]"
      />
      <base-input
        class="col-xs-12 col-md-6 mb20"
        type="text"
        name="bank-account-number"
        :placeholder="$t('Account number for returns')"
        v-model="bankAccountNumber"
        @input="$v.bankAccountNumber.$touch()"
        :validations="[
          {
            condition: !$v.bankAccountNumber.required && $v.bankAccountNumber.$error,
            text: $t('Field is required')
          },
          {
            condition: !$v.bankAccountNumber.numeric && $v.bankAccountNumber.$error,
          },
          {
            condition: !$v.bankAccountNumber.minLength && $v.bankAccountNumber.$error,
            text: $t('The bank account number must contain 26 digits')
          },
          {
            condition: !$v.bankAccountNumber.maxLength && $v.bankAccountNumber.$error,
            text: $t('The bank account number must contain 26 digits')
          }
        ]"
      />
      <!-- Change password (edit mode) -->
      <base-checkbox
        class="col-xs-12 mb20"
        id="changePassword"
        v-model="changePassword"
      >
        {{ $t('Change my password') }}
      </base-checkbox>

      <template v-if="changePassword">
        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="old-password"
          autocomplete="current-password"
          :placeholder="$t('Current password *')"
          v-model="oldPassword"
          @input="$v.oldPassword.$touch()"
          :validations="[{
            condition: !$v.oldPassword.required && $v.oldPassword.$error,
            text: $t('Field is required')
          }]"
        />

        <div class="hidden-xs hidden-sm col-md-6 mb15 mt10" />

        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="password"
          autocomplete="new-password"
          :placeholder="$t('New password *')"
          v-model="password"
          @input="$v.password.$touch()"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required')
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: $t('Password must have at least 6 letters.')
            }
          ]"
        />

        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          :placeholder="$t('Repeat new password *')"
          v-model="rPassword"
          @input="$v.rPassword.$touch()"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: $t('Field is required')
            },
            {
              condition: !$v.rPassword.sameAsPassword,
              text: $t('Passwords must be identical.')
            }
          ]"
        />
      </template>
      <div class="col-xs-12 col-sm-6">
        <button-full
          class="w-100"
          @click.native="updateProfile"
          :disabled="checkValidation()"
        >
          {{ $t('Update my profile') }}
        </button-full>
      </div>
      <div class="col-xs-12 col-sm-6 flex middle-xs">
        <p @click="exitSection" class="h4 m0 cl-black pointer">
          {{ $t('Cancel') }}
        </p>
      </div>
    </div>
    <!-- My profile summary -->
    <div class="row fs16 mb35" v-else>
      <div class="col-xs-12">
        <p class="mt0 cl-light-gray">
          {{ currentUser.firstname }} {{ currentUser.lastname }}
        </p>
        <p class="mt0 cl-light-gray">
          {{ currentUser.email }}
        </p>
        <p class="mt0 cl-light-gray">
          {{ $t('Account number for returns') }}: {{ readBankAccountNumber() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, sameAs, numeric } from 'vuelidate/lib/validators'
import MyProfile from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyProfile'
import { unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
// import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
// import Tooltip from 'theme/components/core/Tooltip'

export default {
  components: {
    BaseCheckbox,
    // BaseSelect,
    BaseInput,
    ButtonFull
    // Tooltip
  },
  mixins: [MyProfile],
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  methods: {
    checkValidation () {
      if (this.changePassword && this.addCompany) {
        return this.$v.$invalid
      } else if (this.changePassword && !this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.password.$invalid || this.$v.rPassword.$invalid
      } else if (!this.changePassword && this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.userCompany.$invalid
      } else {
        return this.$v.currentUser.$invalid || this.$v.bankAccountNumber.$invalid
      }
    }
  },
  validations: {
    currentUser: {
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      }
    },
    bankAccountNumber: {
      required,
      minLength: minLength(26),
      maxLength: maxLength(26),
      numeric
    },
    oldPassword: {
      required
    },
    password: {
      required,
      minLength: minLength(6)
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    userCompany: {
      company: {
        required
      },
      country: {
        required
      },
      street: {
        required,
        unicodeAlphaNum
      },
      house: {
        required,
        unicodeAlphaNum
      },
      postcode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      },
      taxId: {
        required,
        minLength: minLength(3)
      }
    }
  }
}
</script>

<style lang="scss">
.my-profile-custom {
  input, select {
    border-color: #D6D8DE;
  }
}
</style>

<template>
  <div class="mb35 myshippingdetails-custom">
    <!-- My shipping details header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('My addresses') }}
        </h3>
      </div>
    </div>
    <!-- My shipping details body (edit mode) -->
    <div class="row bg-cl-white p30 brdr-1 brdr-cl-black" v-if="isEdited">
      <template>
        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="first-name"
          autocomplete="given-name"
          :placeholder="`${$t('First name')} *`"
          v-model.trim="shippingDetails.firstName"
          @input="$v.shippingDetails.firstName.$touch()"
          :validations="[
            {
              condition: !$v.shippingDetails.firstName.required && $v.shippingDetails.firstName.$error,
              text: $t('Field is required')
            },
            {
              condition: !$v.shippingDetails.firstName.minLength,
              text: $t('Name must have at least 2 letters.')
            }
          ]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="last-name"
          autocomplete="family-name"
          :placeholder="`${$t('Last name')} *`"
          v-model.trim="shippingDetails.lastName"
          @input="$v.shippingDetails.lastName.$touch()"
          :validations="[{
            condition: !$v.shippingDetails.lastName.required && $v.shippingDetails.lastName.$error,
            text: $t('Field is required')
          }]"
        />
        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="company"
          autocomplete="company"
          :placeholder="`${$t('Company')}`"
          v-model.trim="shippingDetails.company"
        />
        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="nip"
          :placeholder="`${$t('Nip')}`"
          v-model.trim="shippingDetails.vat"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="street-address"
          autocomplete="address-line1"
          :placeholder="`${$t('Street name')} *`"
          v-model.trim="shippingDetails.street"
          @input="$v.shippingDetails.street.$touch()"
          :validations="[{
            condition: !$v.shippingDetails.street.required && $v.shippingDetails.street.$error,
            text: $t('Field is required')
          }]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="apartment-number"
          autocomplete="address-line2"
          :placeholder="`${$t('House/Apartment number')} *`"
          v-model.trim="shippingDetails.house"
          @input="$v.shippingDetails.house.$touch()"
          :validations="[{
            condition: !$v.shippingDetails.house.required && $v.shippingDetails.house.$error,
            text: $t('Field is required')
          }]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="city"
          autocomplete="address-level2"
          :placeholder="`${$t('City')} *`"
          v-model.trim="shippingDetails.city"
          @input="$v.shippingDetails.city.$touch()"
          :validations="[
            {
              condition: !$v.shippingDetails.city.required && $v.shippingDetails.city.$error,
              text: $t('Field is required')
            },
            {
              condition: $v.shippingDetails.city.$error && $v.shippingDetails.city.required,
              text: $t('Please provide valid city name')
            }
          ]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="state"
          autocomplete="address-level1"
          :placeholder="$t('State / Province')"
          v-model.trim="shippingDetails.region"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="zip-code"
          autocomplete="postal-code"
          :placeholder="`${$t('Zip-code')} *`"
          v-model.trim="shippingDetails.postcode"
          @input="$v.shippingDetails.postcode.$touch()"
          :validations="[
            {
              condition: !$v.shippingDetails.postcode.required && $v.shippingDetails.postcode.$error,
              text: $t('Field is required')
            },
            {
              condition: !$v.shippingDetails.postcode.minLength,
              text: $t('Zip-code must have at least 3 letters.')
            }
          ]"
        />

        <base-select
          class="col-xs-12 col-sm-6 mb20"
          name="countries"
          :options="countryOptions"
          :selected="shippingDetails.country"
          :placeholder="$t('Country *')"
          :validations="[
            {
              condition: $v.shippingDetails.country.$error && !$v.shippingDetails.country.required,
              text: $t('Field is required')
            }
          ]"
          v-model="shippingDetails.country"
          autocomplete="country-name"
          @blur="$v.shippingDetails.country.$touch()"
          @change="$v.shippingDetails.country.$touch()"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb20"
          type="text"
          name="phone-number"
          autocomplete="tel"
          :placeholder="$t('Phone Number')"
          v-model.trim="shippingDetails.phone"
        />

        <div class="hidden-xs col-sm-6 mb25" />

        <div class="col-xs-12 col-sm-6">
          <button-full
            class="w-100"
            @click.native="updateShippingData"
            :disabled="$v.$invalid"
          >
            {{ $t('Update my shipping details') }}
          </button-full>
        </div>
        <div class="col-xs-12 col-sm-6 flex middle-xs">
          <a href="#" @click="cancelEdit" class="h4 cl-black">
            {{ $t('Cancel') }}
          </a>
        </div>
      </template>
    </div>
    <!-- My shipping details summary -->
    <div class="row fs16 mb35" v-else>
      <div v-if="preEditShipping" class="overlay" @click="setPreEditShipping()" />
      <div class="col-xs-12 col-sm-6 col-xl-5 mb20" v-for="address of addresses" :key="address.id">
        <div class="mx10 addres-box">
          <p class="edit-address" @click="setPreEditShipping(address.id)">
            {{ $t('Manage the address') }}
          </p>
          <div class="main">
            <p class="billing" v-if="address.default_billing">
              {{ $t('Billing address') }}
            </p>
            <p class="shipping" v-if="address.default_shipping">
              {{ $t('Shipping address') }}
            </p>
          </div>
          <div class="overlay-buttons" :class="{ 'active': preEditShipping === address.id }">
            <a href="#" @click.stop="setPreEditShipping()" class="close details">
              <span class="pr5 lh16">
                <i class="icon-close cl-main weight-900 fs16 icon-position" />
              </span>
            </a>
            <a href="#" @click.stop="editCurrent(address)" class="update details">
              <span class="pr5 lh16">
                {{ $t('Edit') }}
              </span>
            </a>
            <a href="#" @click.stop="deleteCurrent(address)" class="delete details">
              <span class="pr5 lh16">
                {{ $t('Remove') }}
              </span>
            </a>
            <a href="#" @click.stop="changeDefaultShipping(address)" class="shipping details" :class="{ 'disabled': address.default_shipping }">
              <span class="pr5 lh16">
                {{ $t('Set as default shipping address') }}
              </span>
            </a>
            <a href="#" @click.stop="changeDefaultBilling(address)" class="billing details" :class="{ 'disabled': address.default_billing }">
              <span class="pr5 lh16">
                {{ $t('Set as default billing address') }}
              </span>
            </a>
          </div>
          <p class="my0 pt20">
            {{ address.firstname }} {{ address.lastname }}
          </p>
          <p class="m0">
            {{ address.company }}
          </p>
          <p class="m0" v-if="address.vat_id">
            NIP: {{ address.vat_id }}
          </p>
          <p class="m0">
            {{ address.street[0] }}
            <span v-if="address.street[1]"> {{ address.street[1] }}</span>
          </p>
          <p class="m0">
            {{ address.city }} {{ address.postcode }}
          </p>
          <div class="pb60">
            {{ $t('Tel') }}: {{ address.telephone }}
            <tooltip v-if="address.telephone">
              {{ $t('Phone number may be needed by carrier') }}
            </tooltip>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-xl-5 mb20">
        <div class="mx10 addres-box new-address" @click="addNewAddress()">
          {{ $t('Add a new address') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import MyShippingDetails from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyShippingDetails'
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'

import ButtonFull from 'theme/components/theme/ButtonFull'
import Tooltip from 'theme/components/core/Tooltip'
// import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import pick from 'lodash-es/pick'
import toString from 'lodash-es/toString'

export default {
  components: {
    ButtonFull,
    Tooltip,
    // BaseCheckbox,
    BaseInput,
    BaseSelect
  },
  mixins: [MyShippingDetails],
  data () {
    return {
      editShippingId: null,
      preEditShipping: null
    }
  },
  methods: {
    setPreEditShipping (id) {
      id
        ? this.preEditShipping = id
        : this.preEditShipping = null
    },
    cancelEdit () {
      this.isEdited = false
      this.editShippingId = null
    },
    editCurrent (address) {
      this.editShippingId = address.id
      this.shippingDetails = this.getDetailsFromCurrentUserById(address)
      this.isEdited = true
      this.setPreEditShipping()
    },
    deleteCurrent (address) {
      let updatedShippingDetails = pick(JSON.parse(JSON.stringify(this.$store.state.user.current)), this.$store.state.config.users.allowModification)
      let currentIndex = updatedShippingDetails.addresses.findIndex(stateAddress => stateAddress.id === address.id)
      updatedShippingDetails.addresses.splice(currentIndex, 1)
      this.exitSection(null, updatedShippingDetails)
      this.setPreEditShipping()
    },
    getDetailsFromCurrentUserById (address) {
      return {
        firstName: address.firstname,
        lastName: address.lastname,
        street: address.street[0],
        house: address.street[1],
        city: address.city,
        postcode: address.postcode,
        region: address.region.region ? address.region.region : '',
        country: address.country_id,
        phone: address.hasOwnProperty('telephone') ? address.telephone : '',
        company: address.hasOwnProperty('company') ? address.company : '',
        vat: address.hasOwnProperty('vat_id') ? address.vat_id : ''
      }
    },
    changeDefaultShipping (address, isCheckout = false) {
      if (address.default_shipping) return
      let updatedShippingDetails = pick(JSON.parse(JSON.stringify(this.$store.state.user.current)), this.$store.state.config.users.allowModification)
      updatedShippingDetails.addresses.forEach(stateAddress => {
        stateAddress.id === address.id
          ? stateAddress.default_shipping = true
          : stateAddress.default_shipping = false
      })
      this.exitSection(null, updatedShippingDetails)
      this.setPreEditShipping()
    },
    changeDefaultBilling (address) {
      if (address.default_billing) return
      let updatedShippingDetails = pick(JSON.parse(JSON.stringify(this.$store.state.user.current)), this.$store.state.config.users.allowModification)
      updatedShippingDetails.addresses.forEach(stateAddress => {
        stateAddress.id === address.id
          ? stateAddress.default_billing = true
          : stateAddress.default_billing = false
      })
      this.exitSection(null, updatedShippingDetails)
      this.setPreEditShipping()
    },
    addNewAddress () {
      this.shippingDetails = {
        firstName: '',
        lastName: '',
        street: '',
        house: '',
        city: '',
        postcode: '',
        region: '',
        country: '',
        phone: '',
        company: '',
        vat: ''
      }
      this.isEdited = true
    },
    updateShippingData () {
      let updatedShippingDetails = pick(JSON.parse(JSON.stringify(this.$store.state.user.current)), this.$store.state.config.users.allowModification)
      let updatedShippingDetailsAddress = {
        firstname: this.shippingDetails.firstName,
        lastname: this.shippingDetails.lastName,
        street: [this.shippingDetails.street, this.shippingDetails.house],
        city: this.shippingDetails.city,
        ...(this.shippingDetails.region ? { region: { region: this.shippingDetails.region } } : {}),
        country_id: this.shippingDetails.country,
        postcode: this.shippingDetails.postcode,
        ...(this.shippingDetails.phone ? { telephone: this.shippingDetails.phone } : {}),
        ...(this.shippingDetails.company ? { company: this.shippingDetails.company } : {}),
        ...(this.shippingDetails.vat ? { vat_id: this.shippingDetails.vat } : {})
      }
      if (!this.editShippingId) {
        updatedShippingDetails.addresses.push(updatedShippingDetailsAddress)
      } else {
        updatedShippingDetails.addresses = updatedShippingDetails.addresses.map(stateAddress =>
          toString(this.editShippingId) === toString(stateAddress.id)
            ? { ...stateAddress, ...updatedShippingDetailsAddress }
            : stateAddress
        )
        this.editShippingId = null
      }
      this.exitSection(null, updatedShippingDetails)
    }
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    addresses () {
      // let addresses = this.$store.state.user.current.addresses
      // return addresses.length
      //   ? addresses.filter(address => !address.default_billing)
      //   : []
      return this.$store.state.user.current.addresses
    }
  },
  validations: {
    shippingDetails: {
      firstName: {
        required,
        minLength: minLength(2),
        unicodeAlpha
      },
      lastName: {
        required
      },
      country: {
        required
      },
      street: {
        required,
        unicodeAlphaNum
      },
      house: {
        required,
        unicodeAlphaNum
      },
      postcode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required,
        unicodeAlpha
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$green: color(green);
$matterhorn: color(matterhorn);
$black: color(black);
.myshippingdetails-custom {
  input, select {
    border-color: #D6D8DE;
  }
}
.addres-box {
  &:hover {
    border-color: #000;
  }
  @media (max-width: 768px) {
    min-height: initial;
  }
  font-size: 0.8em;
  background: white;
  position: relative;
  border: 1px solid #D6D8DE;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  overflow: hidden;
  &.new-address {
    height: 100%;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:$black;
    font-weight: 600;
    background: white;
  }
  .main {
    position: absolute;
    right: 0px;
    top: 0px;
    .shipping {
      margin-top: 5px;
      margin-bottom: 0px;
      text-align: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      font-size: 12px;
      padding: 5px;
      width: fit-content;
      color: white;
      background-color: #FF580E;
      margin-left: auto;
    }
    .billing {
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-size: 12px;
      border-bottom-left-radius: 10px;
      padding: 5px;
      color: white;
      background-color: $green;
    }
  }
  .overlay-buttons {
    &.active {
      right: 0;
      opacity: 1;
    }
    transition: 0.3s all;
    background-color: white;
    position: absolute;
    right: -210px;
    width: 200px;
    bottom: 0;
    top: 0;
    opacity: 0;
    border-radius: 15px;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    box-shadow: -6px 0px 7px #e4e4e4;
    z-index: 2;
    overflow: hidden;
    .details {
      &.close {
        right: 0;
        top: 0;
        padding: 10px;
        position: absolute;
        height: auto;
        z-index: 3;
      }
      &.update {
        color: black;
      }
      &.delete {
        color:black;
      }
      &.shipping {
        &.disabled {
          pointer-events: none;
          color: $matterhorn;
        }
        color: black;
      }
      &.billing {
        &.disabled {
          pointer-events: none;
          color: $matterhorn;
        }
        color: black;
      }
      &:hover {
        background-color: #efefef;
      }
      span {
        text-align: left;
      }
      transition: 0.25s all;
      // border-radius: 15px;
      display: flex;
      padding: 0 15px;
      height: 25%;
      justify-content: left;
      align-items: center;
      font-size: 14px;
      text-align: center;
    }
  }
  .edit-address {
    position: absolute;
    left: 20px;
    bottom: 0px;
    color: $black;
    margin: 0;
    padding: 15px 0px;
  }
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>

<template>
  <div class="mb35">
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('Sell') }}
        </h3>
      </div>
    </div>
    <spinner v-if="isLoading" />
    <template v-else>
      <div id="filters" class="marketplace-status-filters-container hidden-xs">
        <base-checkbox
          class="marketplace-status-filter"
          v-for="filter in filters"
          v-model="filter.value"
          @change="toggleFilters"
          :id="filter.productProperty"
          :key="filter.productProperty"
        >
          {{ filter.label }}
        </base-checkbox>
      </div>
      <div id="multiselect-filters" class="visible-xs">
        <multi-select
          class="col-xs-12 mb10"
          name="product-basic_color"
          v-model="activeFilters"
          :options="filters"
          :placeholder="$t('Filters') + ' *'"
        />
      </div>
      <div v-if="getAllProductsCount != null" id="products-counter" class="mt10 gray-paragraph">
        {{ productsCountString }}
      </div>
      <div class="row">
        <div class="product-box col-xs-6 col-sm-12 attrs-grid" v-for="product in getProducts" :key="product.identifier">
          <div v-if="!preparedAttrs(product).includes('image')" class="product-image-column">
            <div class="product-image-container">
              <img class="product-image" src="/assets/android-icon-500x500.png" alt="product-image">
            </div>
          </div>
          <div v-for="attrName in preparedAttrs(product)" :key="attrName" :class="{'hidden-mobile': isAttrHideOnMobile(attrName), 'product-image-column': attrName === 'image', 'product-status-column': attrName === 'marketplace_status'}">
            <div v-if="attrName === 'image'" class="product-image-container">
              <img class="product-image" v-lazy="`${akaneoEndpiont}/media/cache/thumbnail_small/${getLocalizedValues(attrName, product)}`" alt="product-image">
            </div>
            <div v-else-if="attrName === 'marketplace_status'">
              <div class="product-status" :class="getMarketplaceStatusCode(attrName, product)">
                {{ getLocalizedValues(attrName, product) }}
              </div>
            </div>
            <div v-else-if="attrName === 'marketplace_price'">
              <p class="gray-paragraph">
                {{ `${preparedProductsByLang[attrName].label}:` }}
                {{ getPrice(attrName, product) | price(storeView) }}
              </p>
            </div>
            <div v-else-if="attrName === 'created'">
              <p class="gray-paragraph">
                {{ `${$t('Data wystawienia')}:` }}
                {{ getLocalizedValues(attrName, product) | date('DD.MM.YYYY', storeView) }}
              </p>
            </div>
            <div v-else>
              <p class="gray-paragraph">
                <span class="hidden-xs">{{ preparedProductsByLang[attrName] ? `${preparedProductsByLang[attrName].label}:` : '' }}</span>
                {{ getLocalizedValues(attrName, product) }}
              </p>
            </div>
          </div>
          <div v-if="preparedAttrs(product).includes('marketplace_price')" class="hidden-xs">
            <p class="gray-paragraph">
              {{ `Zarobisz:` }}
              {{ getPrice('marketplace_price', product) * profitFactor | price(storeView) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { Logger } from '@vue-storefront/core/lib/logger'
import { mapGetters } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'
import config from 'config'
import { prepareProductsCountString, sortAttrsWithMap } from 'theme/helpers/selling-products'
import MultiSelect from 'theme/components/core/blocks/Form/MultiSelect'
import Spinner from 'theme/components/core/Spinner'

const WHITE_LIST_ATTRS = [
  'image',
  'identifier',
  'name',
  'marketplace_price',
  'created',
  'size',
  'shoe_size',
  'marketplace_purchase_year',
  'marketplace_condition',
  'designer',
  'basic_color',
  'fabric',
  'jewellery_material',
  'marketplace_status',
  'marketplace_fabric'
]

export default {
  name: 'MySellingProducts',
  components: {
    BaseCheckbox,
    ButtonOutline,
    MultiSelect,
    Spinner
  },
  mixins: [onBottomScroll],
  data () {
    return {
      activeFilters: [],
      filters: [],
      loadingProducts: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getDropdownAttrsOptions: 'selling/getDropdownAttrsOptions',
      getDropdownAttrsList: 'selling/getDropdownAttrsList',
      getPreparedProductAttribures: 'selling/getPreparedProductAttribures',
      getIsLastPage: 'selling/getIsLastPage',
      getProductsPerPage: 'selling/getProductsPerPage',
      getCurrentPage: 'selling/getCurrentPage',
      getProducts: 'selling/getProducts',
      getAllProductsCount: 'selling/getAllProductsCount'
    }),
    akaneoEndpiont () {
      return config.akeneo.endpoint
    },
    storeView () {
      return currentStoreView()
    },
    langCode () {
      return this.storeView.i18n.defaultLocale.replace('-', '_')
    },
    preparedProductsByLang () {
      return this.getPreparedProductAttribures(this.langCode)
    },
    profitFactor () {
      return config.akeneo.profitFactor
    },
    productsCountString () {
      return prepareProductsCountString(this.getAllProductsCount)
    }
  },
  watch: {
    async activeFilters () {
      await this.$store.dispatch('selling/getProducts', { activeFilters: this.activeFilters, currentPage: 1 })
    }
  },
  async beforeMount () {
    this.isLoading = true
    await this.$store.dispatch('selling/getFamilyForCategory')
    await this.$store.dispatch('selling/getAttributes')
    await this.$store.dispatch('selling/getDropdownAttrsOptions', this.getDropdownAttrsList)
    await this.$store.dispatch('selling/getProducts', { activeFilters: this.activeFilters, currentPage: 1 })
    this.isLoading = false
    this.setFilters()
  },
  methods: {
    preparedAttrs (product) {
      const filteredProduct = Object.keys(product).filter(attrName => WHITE_LIST_ATTRS.includes(attrName))
      const filteredAttrs = Object.keys(product.values).filter(attrName => WHITE_LIST_ATTRS.includes(attrName))
      return sortAttrsWithMap([...filteredAttrs, ...filteredProduct], WHITE_LIST_ATTRS)
    },
    toggleFilters () {
      this.activeFilters = this.filters.filter(filter => filter.value)
    },
    setFilters () {
      this.filters = []
      if (this.getDropdownAttrsOptions && this.getDropdownAttrsOptions['marketplace_status']) {
        this.getDropdownAttrsOptions['marketplace_status'].forEach(option => {
          let filterOption = {
            label: option.labels[this.storeView.i18n.defaultLocale.replace('-', '_')],
            productProperty: option.code,
            value: false
          }
          this.filters.push(filterOption)
        });
      }
    },
    async onBottomScroll () {
      if (this.loadingProducts || this.getIsLastPage) return
      this.loadingProducts = true
      try {
        let currentPage = this.getCurrentPage + 1
        this.$store.commit('selling/setCurrentPage', currentPage)
        await this.$store.dispatch('selling/getProducts', { activeFilters: this.activeFilters, currentPage })
      } catch (e) {
        Logger.error('Problem with fetching more products', 'marketplace', e)()
      } finally {
        this.loadingProducts = false
      }
    },
    getLocalizedValues (attrName, product) {
      let findAttrValue = product[attrName] || product.values[attrName][0].data
      if (!attrName || !findAttrValue) return
      if (Array.isArray(findAttrValue)) {
        let result = []
        findAttrValue.forEach(code => {
          result.push(this.findLocalizedAttrOptionLabel(attrName, code, this.langCode))
        })
        return result.join(', ')
      }
      return this.findLocalizedAttrOptionLabel(attrName, findAttrValue, this.langCode) ? this.findLocalizedAttrOptionLabel(attrName, findAttrValue, this.langCode) : findAttrValue
    },
    findLocalizedAttrOptionLabel (attrName, attrValue, langCode) {
      const attrOptions = this.getDropdownAttrsOptions[attrName]
      if (!attrOptions) return
      const localizedAttrOptionLabel = attrOptions.find(attrCode => attrCode.code === attrValue).labels[langCode]
      return localizedAttrOptionLabel
    },
    getStatusLabel (statusCode) {
      if (!this.filters.length) return
      if (!statusCode) return
      return this.filters.find(filter => filter.productProperty === statusCode).label
    },
    getMarketplaceStatusCode (attrName, product) {
      return product.values[attrName][0].data
    },
    getPrice (attrName, product) {
      return product.values[attrName][0].data.find(item => item.currency === this.storeView.i18n.currencyCode).amount
    },
    isAttrHideOnMobile (attrName) {
      return attrName !== 'image' && attrName !== 'marketplace_status' && attrName !== 'name'
    }
  }
}
</script>

<style lang="scss" scoped>
.gray-paragraph {
  color: #707070;
  font-size: 12px;
  margin-right: 10px;
  /* most odd situation for word breaking */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
/* most odd situation for word breaking END*/
  @media (max-width: 575.9px) {
    color: black;
    font-weight: 600;
    margin: 5px 0 0 0;
  }
}
.product-box {
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: -1px;
  @media (max-width: 575.9px) {
    border-top: unset;
    border-bottom: unset;
  }
}
.product-status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100px;
  height: 20px;
  font-size: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 10px;
  &.active {
    background-color: #13BB2F;
  }
  &.verification {
    background-color: #E2AE03;
  }
  &.rejected {
    background-color: #DE7070;
  }
  @media (max-width: 575.9px) {
    margin: 5px 0 10px 0;
  }
}
.product-image-container {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  @media (max-width: 576px) {
    position: relative;
    height: 0;
    padding-top: 120%;
    margin: 0;
  }
}
.product-image-column {
  @media (min-width: 576px) {
    grid-row: 1 / 4;
    display: flex;
  }
}
.product-image {
  object-fit: contain;
  max-width: 100%;
  @media (max-width: 575.9px) {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.attrs-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 130px 1fr 1fr 1fr auto;
  grid-template-rows: auto auto auto;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
  }
}
.product-status-column {
  @media (min-width: 576px) {
    grid-row: 1/4;
    grid-column: -1/-1;
  }
}
.hidden-mobile {
  @media (max-width: 576px) {
    display: none;
  }
}
</style>

<style lang="scss">
.marketplace-status-filters-container {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    display: none;
  }
}
.marketplace-status-filter {
  margin-right: 25px;
  margin-bottom: 5px
}
.marketplace-status-filter input[type=checkbox] {
  &:checked + label {
    &:before {
      border-radius: unset;
    }
    &:after {
      left: 3px;
      width: 10px;
    }
  }
}
.marketplace-status-filter .relative .main-label {
  white-space: nowrap;
  padding-left: 25px;
  text-transform: uppercase;
  &:before {
    border-radius: unset;
  }
}
</style>

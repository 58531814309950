<template>
  <div class="mb35">
    <div class="row mb15 middle-xs">
      <div class="col-xs-6 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('My subscriptions') }}
        </h3>
      </div>
    </div>
    <div class="row" v-show="getUserBalance && !getSubscriptions.length">
      <div class="col-xs-12">
        {{ $t('Na Twoim koncie są aktywne punkty abonamentowe z poprzedniej wersji serwisu e-garderobe. Możesz w dalszym ciągu wykorzystywać te punkty. Również twój poprzedni abonament będzie odnawiany na niezmienionych warunkach.') }}
      </div>
      <div class="mt5 col-xs-12">
        {{ $t('Jeśli chcesz anulować swój abonament skorzystaj z linku w mailu o odnowieniu abonamentu lub napisz na info@e-garderobe.com') }}
      </div>
    </div>
    <div class="row" v-show="!getUserBalance && !getSubscriptions.length">
      <div class="col-xs-12">
        {{ $t('Nie masz jeszcze dodanego abonamentu') }}
      </div>
      <div class="col-xs-12 mt20">
        <button-full
          class="pointer auto-width"
          :link="{ path: '/' + $t('abonament-modowy-wybierz-pakiet') }"
        >
          {{ $t('Aktywuj Plan Abonamentu') }}
        </button-full>
      </div>
    </div>
    <div class="row" v-show="getSubscriptions.length">
      <div class="col-xs-6 col-sm-4 mb10" v-for="(subscription, index) in getSubscriptions" :key="index">
        <div class="active-subscription">
          <i @click="cancelSubscriptionsNotif(subscription.subscription_id)" class="icon-close" />
          <span>{{ subscription.subscription_name }}</span>
          <span>{{ subscription.invoice_amount }}</span>
        </div>
      </div>
    </div>
    <button-full
      class="mt20 pointer auto-width"
      @click.native="refreshData"
    >
      {{ $t('Odśwież dane') }}
    </button-full>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'MyActiveSubscriptions',
  components: {
    ButtonFull
  },
  computed: {
    ...mapGetters({
      getSubscriptions: 'user/getSubscriptions',
      getUserBalance: 'user/getBalance'
    })
  },
  data () {
    return {
      canelId: null
    }
  },
  methods: {
    refreshData () {
      this.$store.dispatch('user/sessionAfterAuthorized', { refresh: true, useCache: false })
    },
    cancelSubscriptionsNotif (id) {
      this.canelId = id
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Czy napewno chczesz anulować abonament?'),
        action2: { label: this.$t('OK'), action: this.cancelSubscriptions },
        action1: { label: this.$t('Cancel'), action: 'close' }
      })
    },
    async cancelSubscriptions () {
      if (!this.canelId) return
      await this.$store.dispatch('user/cancelUserSubscription', { id: this.canelId }).then(res => {
        return this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.$t('Subscription has been canceled.'),
          action1: { label: this.$t('OK') }
        })
      })
      this.canelId = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .active-subscription {
    .icon-close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      border: 1px solid #000;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid #D6D8DE;
    margin: 10px 0;
    height: 100%;
    box-sizing: border-box;
  }
 .auto-width {
   width: fit-content;
 }
</style>

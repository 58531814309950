<template>
  <div class="mb35">
    <div class="row mb15 middle-xs">
      <div class="col-xs-6 col-sm-6">
        <h3 class="m0 mb5 fs28 weight-500">
          {{ $t('My subscription cards') }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-xl-5 mb20 relative card-box" v-for="card of getCards" :key="card.id">
        <span class="remove-card" @click="removeCard(card)"><i class="icon-close cl-black fs16 pointer" /></span>
        <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px" viewBox="0 0 750 471" style="enable-background:new 0 0 750 471;" xml:space="preserve">
          <g id="Front">
            <g id="CardBackground">
              <g id="Page-1_1_">
                <g id="amex_1_">
                  <path id="Rectangle-1_1_" class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                          C0,17.9,17.9,0,40,0z" />
                </g>
              </g>
              <path class="darkcolor greydark"
                    d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
            </g>
            <text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" class="st2 st3 st4">**** **** ****
              {{ card.last4 }}</text>
            <!-- <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" class="st2 st5 st6">JOHN DOE</text>
            <text transform="matrix(1 0 0 1 54.1074 389.8793)" class="st7 st5 st8">cardholder name</text> -->
            <text transform="matrix(1 0 0 1 479.7754 388.8793)" class="st7 st5 st8">{{ $t('expiration') }}</text>
            <text transform="matrix(1 0 0 1 65.1054 241.5)" class="st7 st5 st8">{{ $t('card number') }}</text>
            <g>
              <text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" class="st2 st5 st9">{{ card.exp_month }}/{{ card.exp_year }}</text>
              <text transform="matrix(1 0 0 1 479.3848 417.0097)" class="st2 st10 st11">VALID</text>
              <text transform="matrix(1 0 0 1 479.3848 435.6762)" class="st2 st10 st11">THRU</text>
              <polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9" />
            </g>
            <g id="cchip">
              <g>
                <path class="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                      c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
              </g>
              <g>
                <g>
                  <rect x="82" y="70" class="st12" width="1.5" height="60" />
                </g>
                <g>
                  <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
                </g>
                <g>
                  <path class="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                        c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                        C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                        c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                        c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                </g>
                <g>
                  <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
                </g>
                <g>
                  <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
                </g>
                <g>
                  <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
                </g>
                <g>
                  <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
                </g>
              </g>
            </g>
          </g>
          <g id="Back" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'

export default {
  name: 'MySubscriptionCards',
  components: {
    ButtonFull
  },
  mixins: [onBottomScroll],
  data () {
    return {
      pagination: {
        perPage: 20,
        current: 1,
        enabled: false
      },
      lazyLoadOrdersOnScroll: true
    }
  },
  computed: {
    ...mapGetters({
      getCards: 'user/getCards',
      getSubscriptions: 'user/getSubscriptions',
      getExpiration: 'user/getExpiration',
      getHistory: 'user/getHistory',
      getUserBalance: 'user/getBalance'
    })
  },
  methods: {
    removeCard (card) {
    }
  }
}
</script>

<style lang="scss" scoped>
 .auto-width {
   width: fit-content;
 }

/* FRONT OF CARD */
#svgname {
    text-transform: uppercase;
}
#cardfront {
  cursor: pointer;
}
#cardfront .st2 {
    fill: #FFFFFF;
}

#cardfront .st3 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
}

#cardfront .st4 {
    font-size: 54.7817px;
}

#cardfront .st5 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
}

#cardfront .st6 {
    font-size: 33.1112px;
}

#cardfront .st7 {
    opacity: 0.6;
    fill: #FFFFFF;
}

#cardfront .st8 {
    font-size: 24px;
}

#cardfront .st9 {
    font-size: 36.5498px;
}

#cardfront .st10 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 300;
}

#cardfront .st11 {
    font-size: 16.1716px;
}

#cardfront .st12 {
    fill: #4C4C4C;
}
#cardfront .greydark {
    fill: #616161;
}
#cardfront .grey {
    fill: #bdbdbd;
}
.card-box:hover {
  #cardfront .greydark {
      fill: #424242;
  }
  #cardfront .grey {
    fill: #909090;
  }
}
 @media (max-width: 768px) {
   .remove-card {
      opacity: 1 !important;
   }
 }
.remove-card {
  opacity: 0;
  position: absolute;
  right: 18px;
  top: 10px;
  background-color: white;
  /* left: 0; */
  display: flex;
  border-radius: 55px;
}
.card-box:hover .remove-card {
  opacity: 1;
}
</style>
